import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import getString from '../utils/get-string';

const ForgottenPasswordAffirmation = ({strings}) => (
  <Layout>
    <Seo
      title={getString(strings, "11220911")}
      description={getString(strings, "11253270")}
    />

    <Page type="success">
      <Header closeUrl="/lessons">{getString(strings, "11220916")} </Header>
      <Content center={true}>
        <h1 className="h2 growing text-transform-uppercase">
          {getString(strings, "11253270")}
        </h1>
        <div className="page-text-container">
          <p className="h6">{getString(strings, "11253524")}</p>
        </div>
      </Content>
      <Footer>
        <FooterLinkNext to="/login">{getString(strings, "11220911")}</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220911", "11253270", "11220916", "11253524"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <ForgottenPasswordAffirmation {...data} {...props} />}
  />
)
